<script setup>

</script>

<template>
  <div class="access-denied">
      <div class="access-denied__image">
        <img src="@/assets/access-denied.png" alt="">
      </div>

      <div class="access-denied__title">
        Доступ запрещен
        <span>Доступ к боту заблокирован</span>
      </div>

      <button class="access-denied__button">
        Закрыть
      </button>
  </div>
</template>

<style scoped lang="scss">
.access-denied {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  padding-top: 20px;

  &__image {
    width: 235px;

    img {
      max-width: 100%;
    }
  }

  &__title {
    margin-top: 20px;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: var(--mk-text);

    span {
      display: block;
      font-weight: 400;
      font-size: 18px;
    }
  }

  &__button {
    background-color: var(--mk-background-3);
    color: var(--mk-color-2);
    font-weight: 600;
    font-size: 17px;
    border: none;
    padding: 15px;
    width: 100%;
    outline: none;
    margin-top: 75px;
    text-transform: uppercase;
  }
}
</style>
