import axios from "axios";

const url = 'https://dev.multikassa.ae'

export async function getCoins() {
    return await axios.get(`${url}/api/v1/coins`).then(({ data }) => {
        return data
    }).catch(error => {
        console.log(error)
    })
}

export async function getDeliveries() {
    return await axios.get(`${url}/api/v0/deliveries`).then(({ data }) => {
        return data
    }).catch(error => {
        console.log(error)
    })
}

export async function getCities() {
    return await axios.get(`${url}/api/cities`).then(({ data }) => {
        return data
    }).catch(error => {
        console.log(error)
    })
}

export async function calcOrder(data) {
    const formData = new FormData()
    formData.set('direction', data.direction)
    formData.set('sum', data.sum.toString())
    formData.set('from', data.from.toString())
    formData.set('to', data.to.toString())
    formData.set('city', data.city.toString())

    return await axios.post(`${url}/api/exchange`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: '*/*'
        }
    }).then(({ data }) => {
        return data
    }).catch(error => {
        console.log(error)
    })
}

export async function login(data) {
    return await axios.get(`${url}/getClient`, {
        headers: {
            'Tg-query': data
        }
    }).then(({ data }) => {
        return data;
    }).catch(error => {
        console.log(error)
        return {
            error
        }
    })
}
