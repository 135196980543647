<script setup>
import ExchangeForm from '@/components/ExchangeForm.vue'
import ExchangeSelectCoin from '@/components/ExchangeSelectCoin.vue'
import {computed, onMounted, ref, watch} from 'vue'
import {calcOrder, getCities, getCoins, getDeliveries} from "@/api/general";

const telegram = window.Telegram
const formStatus = ref('form')
const coins = ref([])
const deliveries = ref([])
const cities = ref([])
const delivery = ref({})
const loading = ref(true)
const rate = ref(0)
const sum = ref({
  to: null,
  from: null
})

onMounted(async () => {
  telegram.WebApp.MainButton.setParams({
    text: 'ОБМЕНЯТЬ!',
    text_color: '#000000',
    color: '#D7B27B'
  })

  telegram.WebApp.MainButton.show()

  loading.value = true
  coins.value = await getCoins()
  deliveries.value = await getDeliveries()
  cities.value = await getCities()
  delivery.value = deliveries.value.find(delivery => delivery.city === 0)
  await getRate()
  loading.value = false

  setInterval(getRate, 3000)
});

async function getRate() {
  let resultSum = 0

  if (typeof sum.value?.from === "string") {
    resultSum = sum.value?.from?.replace(/[^.\d]+/g, '')
  } else {
    resultSum = sum.value?.from
  }

  const result = await calcOrder({
    direction: 'from',
    sum: resultSum || 0,
    from: fromCoin.value.id,
    to: toCoin.value.id,
    city: delivery.value.city
  })

  rate.value = result.calc?.curs || result?.curs
}

watch(() => [delivery.value],
    async () => {
        await getRate()
    })

const fromCoin = computed(() => {
  return coins.value?.find(coin => coin.id === delivery.value?.from) || null
})

const toCoin = computed(() => {
  return coins.value?.find(coin => coin.id === delivery.value?.to) || null
})

const fromCoins = computed(() => {
      const result = []
      coins.value.filter(coin => deliveries.value.find(delivery => delivery.coin_from === coin.coin) !== undefined)
          .forEach(coin => {
            const resultDeliveries = []

            deliveries.value.forEach(delivery => {
              if (delivery.coin_from === coin.coin && !resultDeliveries.find(({ city }) => {
                if (delivery.coin_from === coin.coin) {
                  if (delivery.city !== 0 && coin.cash === 1 && delivery.city !== city) {
                    return false
                  }

                  return true
                }
                return false
              })) {
                resultDeliveries.push(delivery)
              }
            })

            resultDeliveries.forEach(delivery => {
              const selectCoin = {
                ...coin,
                label: coin.name,
                city: null
              }

              if (delivery?.city !== 0 && coin.cash === 1) {
                selectCoin.city = cities.value.data.find(city => city.id === delivery?.city) || null
                selectCoin.label = `${selectCoin.label} в ${selectCoin.city?.name_prepositional_case}`
              }

              result.push(selectCoin)
            })
          })

      return result
    }
)

const toCoins = computed(() => {
      const result = []
      coins.value.filter(coin => deliveries.value.find(delivery => delivery.coin_from === fromCoin.value.coin && delivery.coin_to === coin.coin) !== undefined)
          .forEach(coin => {
            const resultDeliveries = []

            deliveries.value.forEach(delivery => {
              if (delivery.coin_to === coin.coin && !resultDeliveries.find(({ city }) => {
                if (delivery.coin_to === coin.coin) {
                  if (delivery.city !== 0 && coin.cash === 1 && delivery.city !== city) {
                    return false
                  }

                  return true
                }
                return false
              })) {
                resultDeliveries.push(delivery)
              }
            })

            resultDeliveries.forEach(delivery => {
              const selectCoin = {
                ...coin,
                label: coin.name,
                city: null
              }

              if (delivery?.city !== 0 && coin.cash === 1) {
                selectCoin.city = cities.value.data.find(city => city.id === delivery?.city) || null
                selectCoin.label = `${selectCoin.label} в ${selectCoin.city?.name_prepositional_case}`
              }

              result.push(selectCoin)
            })
          })

      return result
    }
)

function numberFormat (number, decimals = 0, decPoint = '.', thousandsPoint = ' ') {
  if (decimals === null) {
    number = parseFloat(number).toString()
  } else {
    number = parseFloat(number).toFixed(decimals)
  }

  number = number.replace('.', decPoint)

  const splitNum = number.split(decPoint)
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsPoint)
  number = splitNum.join(decPoint)

  return number
}

function selectFrom(coin) {
  delivery.value = deliveries.value.find(delivery => delivery.coin_from === coin.coin && delivery.coin_to === toCoin.value.coin) ||
      deliveries.value.find(delivery => delivery.coin_from === coin.coin)

  formStatus.value = 'form'
}

function selectTo(coin) {
  delivery.value = deliveries.value.find(delivery => delivery.coin_from === fromCoin.value.coin && delivery.coin_to === coin.coin) ||
      deliveries.value.find(delivery => delivery.coin_to === coin.coin)

  formStatus.value = 'form'
}
</script>

<template>
  <div class="preloader" v-if="loading">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
  </div>
  <div class="exchange" v-else>
    <div class="exchange__top">
      <div class="exchange__top-text">
        <span class="top">
          Курс
        </span>

        <div class="rate">
          {{ fromCoin?.coin }} <span>//</span> {{ toCoin?.coin }}

          <div class="rate__image">
            <svg width="8" height="7" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.5 0.499999C7.5 0.223857 7.27614 -4.30791e-07 7 -5.57231e-07L2.5 -1.56836e-07C2.22386 -3.25423e-07 2 0.223857 2 0.5C2 0.776142 2.22386 1 2.5 1L6.5 1L6.5 5C6.5 5.27614 6.72386 5.5 7 5.5C7.27614 5.5 7.5 5.27614 7.5 5L7.5 0.499999ZM1.35355 6.85355L7.35355 0.853553L6.64645 0.146446L0.646447 6.14645L1.35355 6.85355Z" fill="white"/>
            </svg>
          </div>

          <div class="rate__result">
            {{ numberFormat(parseFloat((rate?.replace(/[^.\d]+/g, ''))), (delivery?.reverse ? toCoin?.decimal_places : fromCoin?.decimal_places) || 2) }}
            {{ delivery?.reverse ? toCoin?.symbol : fromCoin?.symbol }}
          </div>
        </div>
      </div>

      <div class="exchange__top-icon">
        <img src="@/assets/exchange-icon.png" alt="">
      </div>
    </div>

    <exchange-form
        v-if="formStatus === 'form'"
        @selecting-to="formStatus = 'select-to'"
        @selecting-from="formStatus = 'select-from'"
        :to-coin="toCoin"
        :from-coin="fromCoin"
        :deliveries="deliveries"
        :rate="parseFloat(rate?.replace(/[^.\d]+/g, ''))"
        :delivery="delivery"
        @change-coins="(newDelivery) => delivery = newDelivery"
        @change-from="(from) => sum.from = from"
        @change-to="(to) => sum.to = to"
    />

    <exchange-select-coin
        v-else-if="formStatus === 'select-from'"
        @selectCoin="selectFrom"
        :coins="fromCoins"
    />

    <exchange-select-coin
        v-else-if="formStatus === 'select-to'"
        @selectCoin="selectTo"
        :coins="toCoins"
    />

    <div class="exchange__result">
      <div class="exchange__result-item">
        <div class="exchange__result-left">
          <div class="icon">
            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 1C6 0.723857 5.77614 0.5 5.5 0.5L1 0.5C0.723858 0.5 0.5 0.723857 0.5 1C0.5 1.27614 0.723858 1.5 1 1.5L5 1.5L5 5.5C5 5.77614 5.22386 6 5.5 6C5.77614 6 6 5.77614 6 5.5L6 1ZM0.853553 6.35355L5.85355 1.35355L5.14645 0.646446L0.146447 5.64645L0.853553 6.35355Z" fill="white"/>
            </svg>
          </div>

          Отдаете
        </div>

        <div class="exchange__result-right">
          {{ sum.from }}
          <span>
            <img :src="fromCoin.icon" alt="">

            {{ fromCoin.coin }}
          </span>
        </div>
      </div>
      <div class="exchange__result-item">
        <div class="exchange__result-left">
          <div class="icon">
            <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 6C6 6.27614 5.77614 6.5 5.5 6.5L1 6.5C0.723858 6.5 0.5 6.27614 0.5 6C0.5 5.72386 0.723858 5.5 1 5.5L5 5.5L5 1.5C5 1.22386 5.22386 1 5.5 1C5.77614 1 6 1.22386 6 1.5L6 6ZM0.853553 0.646447L5.85355 5.64645L5.14645 6.35355L0.146447 1.35355L0.853553 0.646447Z" fill="white"/>
            </svg>
          </div>

          Получаете
        </div>

        <div class="exchange__result-right">
          {{ sum.to }}
          <span>
            <img :src="toCoin.icon" alt="">

            {{ toCoin.coin }}
          </span>
        </div>
      </div>
      <div class="exchange__result-item">
        <div class="exchange__result-left">
          <div class="icon no-background">
            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_650_110881)">
                <path d="M9.94269 4.15156C9.89378 4.0919 9.82081 4.05707 9.74425 4.05707H8.77101C8.53083 3.42359 8.21794 2.80291 7.86229 2.56862C6.63197 1.75923 3.36863 1.75923 2.13831 2.56862C1.7824 2.80317 1.47079 3.42462 1.22934 4.05707H0.256093C0.178766 4.05707 0.106048 4.0919 0.057655 4.15156C0.00900567 4.21096 -0.0104541 4.28957 0.00542099 4.36511L0.149577 5.06363C0.174157 5.1827 0.279138 5.26771 0.400249 5.26771H0.68856C0.410235 5.5888 0.275297 5.99311 0.273504 6.39768C0.271712 6.8975 0.461445 7.34918 0.808391 7.66874C0.812232 7.67207 0.816073 7.67437 0.819657 7.6777V8.65507C0.819657 8.86682 0.991722 9.03915 1.20373 9.03915H2.10144C2.31345 9.03915 2.48551 8.86682 2.48551 8.65507V8.26228H7.51457V8.65507C7.51457 8.86682 7.68664 9.03915 7.89865 9.03915H8.79636C9.00785 9.03915 9.18043 8.86682 9.18043 8.65507V7.69691C9.54863 7.35968 9.72453 6.89571 9.72684 6.4325C9.72838 6.01411 9.58729 5.59495 9.29258 5.26719H9.60009C9.72172 5.26719 9.8267 5.18218 9.85077 5.06286L9.99518 4.3646C10.01 4.28957 9.99134 4.21148 9.94269 4.15156ZM2.56002 3.21004C3.54069 2.56453 6.4594 2.56453 7.43955 3.21004C7.63005 3.335 7.87509 3.8402 8.09965 4.45883H1.90044C2.12448 3.84045 2.36952 3.33551 2.56002 3.21004ZM1.41139 6.49396C1.41139 6.07659 1.74988 5.73859 2.16699 5.73859C2.5846 5.73859 2.92259 6.07659 2.92259 6.49396C2.92259 6.91133 2.5846 7.24958 2.16699 7.24958C1.74988 7.24958 1.41139 6.91133 1.41139 6.49396ZM7.84309 7.24958C7.42598 7.24958 7.08748 6.91133 7.08748 6.49396C7.08748 6.07659 7.42598 5.73859 7.84309 5.73859C8.2607 5.73859 8.59869 6.07659 8.59869 6.49396C8.59869 6.91133 8.26019 7.24958 7.84309 7.24958Z" fill="#D7B27B"/>
              </g>
              <defs>
                <clipPath id="clip0_650_110881">
                  <rect width="10" height="10" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </div>

          Доставка
        </div>

        <div class="exchange__result-right">
          <span class="green">
            FREE
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.exchange {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  padding-top: 20px;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  &__top-text {
    .top {
      color: #BAB8B4;
      font-weight: 400;
      font-size: 15px;
    }

    .rate {
      display: flex;
      gap: 4px;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: var(--mk-text);

      span {
        opacity: 0.2;
      }

      &__image {
        background-color: #32AD13;
        width: 12px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
      }

      &__result {
        color: #32AD13;
      }
    }
  }

  &__top-icon {
    width: 60px;

    img {
      max-width: 100%;
    }
  }

  &__result {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 15px;
    margin-bottom: 15px;
  }

  &__result-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__result-left {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 500;
    font-size: 16px;
    color: var(--mk-active);

    .icon {
      background-color: var(--mk-active);
      width: 10px;
      height: 10px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.no-background {
        background-color: transparent;
      }
    }
  }

  &__result-right {
    font-weight: 500;
    font-size: 16px;
    color: var(--mk-text);
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      background-color: var(--mk-background-2);
      border-radius: 6px;
      padding: 4px 8px;
      font-weight: 600;
      font-size: 10px;
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        max-width: 10px;
      }

      &.green {
        color: #32AD13;
        text-transform: uppercase;
      }
    }
  }
}
</style>
