<script setup>

</script>

<template>
  <div class="no-auth">
      <div class="no-auth__image">
        <img src="@/assets/no-auth.png"  alt=""/>
      </div>

      <div class="no-auth__title">
        Вы не авторизированы
        <span>Отправьте в чат Ваш номер телефона</span>
      </div>

      <button class="no-auth__button">
        Отправить номер телефона
      </button>
  </div>
</template>

<style scoped lang="scss">
  .no-auth {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding-top: 20px;

    &__image {
      max-width: 185px;

      img {
        max-width: 100%;
      }
    }

    &__title {
      margin-top: 20px;
      color: var(--mk-text);
      font-weight: 500;
      font-size: 25px;
      text-align: center;
      font-family: SF Pro Display, sans-serif;

      span {
        display: block;
        font-weight: 400;
        font-size: 18px;
      }
    }

    &__button {
      margin-top: 70px;
      background-color: var(--mk-active);
      border: none;
      outline: none;
      padding: 14px;
      font-weight: 600;
      font-size: 17px;
      text-transform: uppercase;
      width: 100%;
    }
  }
</style>
