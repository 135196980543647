<script setup>
import {ref, defineProps, defineEmits, defineExpose, watch} from 'vue'

const from = ref()
const to = ref()
const emit = defineEmits(['changeCoins', 'changeFrom', 'changeTo'])

const props = defineProps({
  toCoin: {},
  fromCoin: {},
  delivery: {},
  rate: Number,
  deliveries: Array
})

watch(() => [from.value],
    () => {
      emit('changeFrom', from.value)
    }
  )

watch(() => [to.value],
    () => {
      emit('changeTo', to.value)
    }
)

function onInputTo(event) {
  to.value = event.target.value?.replace(/[^.\d]/g, '')
  if (!to.value || to.value === '.') {
    to.value = ''
    from.value = ''
    return
  }
  const result = parseFloat(to.value)
  if (!props.delivery.reverse) {
    from.value = numberFormat(result * props.rate, props.fromCoin?.decimal_places || 2)
  } else {
    from.value = numberFormat(result / props.rate, props.fromCoin?.decimal_places || 2)
  }
  to.value = numberFormat(to.value)
}

function onInputFrom(event) {
  from.value = event.target.value?.replace(/[^.\d]/g, '')
  if (!from.value || from.value === '.') {
    to.value = ''
    from.value = ''
    return
  }

  const result = parseFloat(from.value)
  if (!props.delivery.reverse) {
    to.value = numberFormat(result / props.rate, props.toCoin?.decimal_places || 2)
  } else {
    to.value = numberFormat(result * props.rate, props.toCoin?.decimal_places || 2)
  }
  from.value = numberFormat(from.value)
}

function changeCoins() {
  const delivery = props.deliveries.find(delivery =>
      delivery.coin_from === props.toCoin.coin && delivery.coin_to === props.fromCoin.coin &&
      delivery.city === props.delivery.city
  ) || props.deliveries.find(delivery => delivery.coin_from === props.toCoin.coin && delivery.city === props.delivery.city)

  console.log(delivery, props.toCoin.coin, props.toCoin)

  if (delivery) {
    emit('changeCoins', delivery)
  }
}

function numberFormat (number, decimals = 0, decPoint = '.', thousandsPoint = ' ') {
  if (decimals === null) {
    number = parseFloat(number).toString()
  } else {
    number = parseFloat(number).toFixed(decimals)
  }

  number = number.replace('.', decPoint)

  const splitNum = number.split(decPoint)
  splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsPoint)
  number = splitNum.join(decPoint)

  return number
}

defineExpose({
  from,
  to
})
</script>

<template>
  <div class="exchange__form">
    <div class="exchange__form-item">
      <div class="exchange__form-left">
        <span>Что отдаете?</span>
        <input
            type="text"
            :placeholder="numberFormat(delivery.min_sum_multikassa, fromCoin.decimal_places || 2)"
            v-model="from"
            @input="onInputFrom"
        >
      </div>

      <div class="exchange__form-right">
        <button class="exchange__form-select-rate" @click="$emit('selectingFrom')">
          <img :src="fromCoin.icon" alt="">

          {{ fromCoin.coin }}

          <span class="arrow">
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.64451 9.79425L0.478318 1.2288C0.221583 0.947722 0.221583 0.491879 0.478318 0.210806C0.735034 -0.0702685 1.15158 -0.0702685 1.40832 0.210806L8.14293 8.2598L14.8775 0.211519C15.1342 -0.0695549 15.5508 -0.0695549 15.8075 0.211519C16.0642 0.492593 16.0642 0.948436 15.8075 1.22949L8.64132 9.79496C8.50446 9.94477 8.32302 10.009 8.14358 9.99899C7.96347 10.0083 7.78205 9.9441 7.64451 9.79425Z" fill="#D7B27B"/>
            </svg>
            </span>
        </button>
      </div>
    </div>

    <div class="exchange__form-change">
      <button @click="changeCoins">
        <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.2797 0.957699C9.08444 0.762438 8.76786 0.762438 8.57259 0.957699L5.39061 4.13968C5.19535 4.33494 5.19535 4.65152 5.39061 4.84679C5.58588 5.04205 5.90246 5.04205 6.09772 4.84679L8.92615 2.01836L11.7546 4.84679C11.9498 5.04205 12.2664 5.04205 12.4617 4.84679C12.6569 4.65152 12.6569 4.33494 12.4617 4.13968L9.2797 0.957699ZM9.42615 18.2949L9.42615 1.31125L8.42615 1.31125L8.42615 18.2949L9.42615 18.2949Z"/>
          <path d="M4.42728 18.6481C4.23202 18.8433 3.91544 18.8433 3.72018 18.6481L0.538196 15.4661C0.342934 15.2708 0.342934 14.9542 0.538196 14.759C0.733458 14.5637 1.05004 14.5637 1.2453 14.759L4.07373 17.5874L6.90216 14.759C7.09742 14.5637 7.414 14.5637 7.60926 14.759C7.80453 14.9542 7.80453 15.2708 7.60926 15.4661L4.42728 18.6481ZM4.57373 1.31091L4.57373 18.2945L3.57373 18.2945L3.57373 1.31091L4.57373 1.31091Z"/>
        </svg>
      </button>
    </div>

    <div class="exchange__form-item">
      <div class="exchange__form-left">
        <span>Что получаете?</span>
        <input
            type="text"
            :placeholder="numberFormat(delivery.reverse ? delivery.min_sum_multikassa * rate : delivery.min_sum_multikassa / rate, toCoin.decimal_places || 2)"
            v-model="to"
            @input="onInputTo"
        >
      </div>

      <div class="exchange__form-right">
        <button class="exchange__form-select-rate" @click="$emit('selectingTo')">
          <img :src="toCoin.icon" alt="">

          {{ toCoin?.coin }}

          <span class="arrow">
              <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.64451 9.79425L0.478318 1.2288C0.221583 0.947722 0.221583 0.491879 0.478318 0.210806C0.735034 -0.0702685 1.15158 -0.0702685 1.40832 0.210806L8.14293 8.2598L14.8775 0.211519C15.1342 -0.0695549 15.5508 -0.0695549 15.8075 0.211519C16.0642 0.492593 16.0642 0.948436 15.8075 1.22949L8.64132 9.79496C8.50446 9.94477 8.32302 10.009 8.14358 9.99899C7.96347 10.0083 7.78205 9.9441 7.64451 9.79425Z" fill="#D7B27B"/>
            </svg>
            </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.exchange {
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding: 0 15px;
  }

  &__form-item {
    padding: 20px;
    background-color: var(--mk-background-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
  }

  &__form-left {
    color: var(--mk-text);
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;

    input {
      font-weight: 500;
      font-size: 30px;
      line-height: 39px;
      max-width: 160px;
      background-color: transparent;
      border: none;
      outline: none;
      color: var(--mk-text);

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    span {
      font-size: 16px;
      display: block;
      color: var(--mk-color-3);
      line-height: 20.8px;
      margin-bottom: 4px;
    }
  }

  &__form-right {
    height: 100%;
  }

  &__form-select-rate {
    width: 145px;
    height: 100%;
    background-color: var(--mk-background);
    border-radius: 10px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 0 12px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    font-weight: 500;
    font-size: 16px;
    color: var(--mk-text);

    &:hover {
      opacity: 0.8;
    }

    .arrow {
      margin-left: auto;
    }

    img {
      max-width: 20px;
    }
  }

  &__form-change {
    position: absolute;
    right: 60px;
    top: calc(50% - 22.5px);
    width: 45px;
    height: 45px;
    background-color: var(--mk-background-2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;

    button {
      background-color: var(--mk-active);
      border: none;
      outline: none;
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      cursor: pointer;
      transition: ease-in-out 0.2s;

      svg path {
        fill: var(--mk-background-2)
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
