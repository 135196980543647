<script setup>
import AuthPage from './components/AuthPage.vue'
import AccessDeniedPage from '@/components/AccessDeniedPage.vue'
import ExchangePage from '@/components/ExchangePage.vue'
import { onMounted, ref } from 'vue'
import { login } from '@/api/general'

const page = ref('exchange')
const user = ref({})
const loading = ref(true)
const telegram = window.Telegram

onMounted(async () => {
  loading.value = true
  document.body.classList.remove(...document.body.classList)
  document.body.classList.add(telegram.WebApp.colorScheme)
  telegram.WebApp.onEvent('themeChanged', () => {
    document.body.classList.remove(...document.body.classList)
    document.body.classList.add(telegram.WebApp.colorScheme)
  })

  user.value = await login(telegram.WebApp.initData)
})

</script>

<template>
  <div class="preloader" v-if="loading">
<!--    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>-->
    data: {{ user }}
  </div>
  <div v-else>
    <auth-page v-if="page === 'no-auth'" />
    <access-denied-page v-if="page === 'access-denied'" />
    <exchange-page v-if="page === 'exchange'" />
  </div>
</template>

<style lang="scss">
:root {
  --mk-background: var(--tg-theme-bg-color);
  --mk-text: #1F1E1C;
  --mk-background-2: #F5F5F5;
  --mk-active: #D7B27B;
  --mk-background-3: #E8E8E9;
  --mk-color-2: #B9B9BA;
  --mk-color-3: #9F9F9E;
}

body.dark {
  --mk-background: var(--tg-theme-bg-color);
  --mk-text: #FFFFFF;
  --mk-background-2: #333230;
  --mk-background-3: #000000;
  --mk-color-2: rgba(185, 185, 186, 0.3);
  --mk-color-3: #BAB8B4;
}

body {
  background-color: var(--mk-background);
}

.preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-ring {
  /* change color here */
  color: var(--mk-active);
}
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
